import React, { lazy, useEffect, useState } from 'react'
import axios from 'axios'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'

// var firebaseConfig = {
//   apiKey: "AIzaSyBfxETK3J4ywQ-JTyUK5z2Nz2tUY9zEZAM",
//   authDomain: "fantompump.firebaseapp.com",
//   projectId: "fantompump",
//   storageBucket: "fantompump.appspot.com",
//   messagingSenderId: "511398479888",
//   appId: "1:511398479888:web:11dfd9f1836e27b9372295",
//   measurementId: "G-64KK0SXYBV",
//   databaseURL:
//     "https://fantompump-default-rtdb.europe-west1.firebasedatabase.app",
// };
// firebase.initializeApp(firebaseConfig);
// var database = firebase.database();

// const Push = () => {
//   database.ref("test").set({
//     name : 'seg',
//     age : '11',
//   }).catch(alert);
// }

// Push()

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const CreateAccount = lazy(() => import('./pages/CreateAccount'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))


const saveSnapshot = async (snapshot, addresses, note) => {
  try {

      const saved = localStorage.getItem("snapshots");
      const initialValue = JSON.parse(saved);
      const prevSnapshots = initialValue || [];
      
      localStorage.setItem(`snapshots`, JSON.stringify([...prevSnapshots, {timestamp: Date.now(), snapshot: snapshot, addresses: addresses, note: note}]));

    } catch (error) {

    }
}

function App() {

  const [loadingSnapshot, setLoadingSnapshot] = useState(false)
  const [data, setData] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [snapshotTimestamp, setSnapshotTimestamp] = useState(null)

  const [addresses, setAddresses] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("addresses");
    const initialValue = JSON.parse(saved);
    return initialValue || [];
  });

  useEffect(() => {

    if (!loadingSnapshot) {

      setLoaded(false)

      // POST request using axios inside useEffect React hook
      const request = { addresses: addresses };
      axios.post('https://yeho.vercel.app/api/data', request)
        .then(response => {
          if (response.data.all[0][1].USD != 0) setLoaded(true)
          setData(response.data)
        }, error => {
          axios.post('https://yeho.vercel.app/api/data', request)
            .then(response => {
              if (response.data.all[0][1].USD != 0) setLoaded(true)
              setData(response.data)
          }, error => {
            axios.post('https://yeho.vercel.app/api/data', request)
              .then(response => {
                if (response.data.all[0][1].USD != 0) setLoaded(true)
                setData(response.data)
          });
        })
      })

    } else {
      setLoaded(true)
    }

  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [addresses]);

  useEffect(() => {
    localStorage.setItem('addresses', JSON.stringify(addresses))
    
  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [loadingSnapshot]);

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />

          {/* Place new routes over this */}
          <Route path="/app" render={()=> <Layout snapshotTimestamp={snapshotTimestamp} setSnapshotTimestamp={setSnapshotTimestamp} setData={setData} loadingSnapshot={loadingSnapshot} setLoadingSnapshot={setLoadingSnapshot} loaded={loaded} data={data} addresses={addresses} setAddresses={setAddresses} saveSnapshot={saveSnapshot} />} />
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </>
  )
}

export default App
